export const userAlreadyAddedToBasket = () => !!localStorage.getItem('usedAddedDigitalCoupon')

export const setAlreadyAddedToBasket = (isAdded = true) => {
  if (isAdded) localStorage.setItem('usedAddedDigitalCoupon', 'true')
  else localStorage.removeItem('usedAddedDigitalCoupon')
}

export const setDontShowPrintConf = (dontShow = true) => {
  if (dontShow) localStorage.setItem('dontShowClipConf', 'true')
  else localStorage.removeItem('dontShowClipConf')
}

export const handleGetNumber = () => {
  const tel = document.getElementById('digit-1').value
  const tel1 = document.getElementById('digit-2').value
  const tel2 = document.getElementById('digit-3').value
  const tel3 = document.getElementById('digit-4').value
  const tel4 = document.getElementById('digit-5').value
  const tel5 = document.getElementById('digit-6').value
  const tel6 = document.getElementById('digit-7').value
  const tel7 = document.getElementById('digit-8').value
  const tel8 = document.getElementById('digit-9').value
  const tel9 = document.getElementById('digit-10').value
  // for (let i = 1; i <= 10; i += 1) {
  //   document.getElementById(`digit-${i}`).value = ''
  // }
  return (+tel + tel1 + tel2 + tel3 + tel4 + tel5 + tel6 + tel7 + tel8 + tel9)
}

export const handleerrorMessages = (statusMessage, errors) => {
  switch (statusMessage) {
    case 'ALREADY_AVAILED':
      return errors.ALREADY_AVAILED
    case 'INVALID_INCENTIVE':
      return errors.INVALID_INCENTIVE
    case 'INVENTORY_OUT_OF_STOCK':
      return errors.INVENTORY_OUT_OF_STOCK
    case 'INCENTIVE_EXPIRED':
      return errors.INCENTIVE_EXPIRED
    case 'CONSUMER_NOT_ELIGIBLE':
      return errors.CONSUMER_NOT_ELIGIBLE
    case 'INVALID_PROMOCODE ':
      return errors.INVALID_PROMOCODE
    case 'TRY_AGAIN':
      return errors.TRY_AGAIN
    default:
      return errors.GENERIC_ERROR
  }
}
