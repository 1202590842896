import Router from 'next/router'
import digitalCouponsStore from '../containers/DigitalCoupons/digitalCoupons.store'
import CouponsStore from '../containers/Coupons/Coupons.store'
import {
  handleCheckRewardTransactionType,
  fetchPointsBalanceApi,
  issueInteractionApi,
  issueRewardApi,
} from './api.helper'
import {
  defaultCharity,
  loginContextText,
  OFFER_TYPE,
  iohVideoGatingCampaign,
  enrollmentLocationExternalReference,
} from '../constants/texts'
import { enrollPGGEUserWithWelcomeEmail, storageAvailable } from '../adapters/serverside.adapters'
import LoginStore from '../components/UserManagement/Login-store'
import {
  getLoginCount,
  setLoginCount,
} from '../okta/forms/LoginForm/Login.helper'
import staticRoutes, { myCoupons, olayCouponsCampaign } from '../../config/router.config'
import {
  handleGetIncentiveData,
  handleSession,
  handleEnrollAndRewardAssignment,
  handleRedirectToCharity,
  handleGetCharityIdDuringRegistration,
} from './login.helper'
import redirectTo, { getParameterByName } from './redirect.helpers'
import { handleLoadCompleteProfileSurvey } from './segmanta.helper'
import {
  userAlreadyAddedToBasket,
  setAlreadyAddedToBasket,
} from '../containers/DigitalCoupons/digitalCoupon.helper'
import { redirectToEarnPointsAfterLogin } from './layout.helper'
import aimiaStore from '../containers/AIMIA/aimia.store'
import getSurveyId from '../containers/SurveyLanding/Survey.helper'
import { revtraxDigitalCouponsCampaign } from '../constants/page-source'

const { REWARD, REWARD_CATALOG, OFFER_CATALOG } = OFFER_TYPE
let redirectionUrlAfterSkipping2Screen = null

/**
 * This function checks for promo eligibility
 * @param {*} rewardId pass the reward id
 */
const promoEligibilityCheck = async (rewardId) => {
  await aimiaStore.fetchMemberHistoryAction()
  const tideReward = aimiaStore.issuedRewards
    && aimiaStore.issuedRewards.filter(
      (item) => item.rewardTypeExternalReference === rewardId,
    )
  if (tideReward?.length > 0) return false
  return true
}

export const promoEligibilityCheckNewHistory = async (rewardId = []) => {
  await aimiaStore.fetchMemberHistoryAction()
  const issueRewards = aimiaStore.redeemRewards.filter(
    (item) => rewardId.includes(item?.details[0]?.rewardCode),
  )
  if (issueRewards?.length > 0) return false
  return true
}

/**
 * This function checks for the PGE user
 * it checks for the account created date from 115_12 opt-in and based on that decides the PGE user
 * @param {*} profileData from the login api response
 */
export const checkForPGEUser = (profileData) => {
  const accCreationDate = new Date(
    profileData?.optIns
      ?.find((opt) => ['115_12'].includes(opt?.optId))
      .optChoiceDate.substring(0, 10),
  )
  const PGELimitDate = new Date('2020-01-01')
  return accCreationDate < PGELimitDate
}

/**
 * This function handles the redirection after login for various login flows
 * @param {*} pageSource pass page name form which the login flow started
 * @param {*} campaignName utm_campaign name for login
 */
const handleConfirmationModalClose = async (pageSource, campaignName) => {
  const pendingFor = localStorage.getItem('pendingfor')
  LoginStore.showCompleteProfile20OrBuildYourProfileSurvey = false
  if (pendingFor === 'completeProfileSurvey') {
    LoginStore.pendingFor = pendingFor
    localStorage.removeItem('pendingfor')
  } else if (pendingFor?.includes('PAHCouponId')) {
    const [, id] = pendingFor.split('-')
    LoginStore.pendingFor = Number(id)
    LoginStore.pendingForSignup = Number(id)
    CouponsStore.addToBasketPendingWithLogin = true
  }

  if (campaignName === 'tapjoy' && LoginStore.videoGating === true) {
    redirectTo(staticRoutes.earnPoints, staticRoutes.earnPoints)
    localStorage.setItem('disableCompleteProfileOverlay', 'true')
  } else if (
    campaignName?.includes('video-gating')
    && LoginStore.videoGating === true
  ) {
    redirectTo(staticRoutes.earnPoints, staticRoutes.earnPoints)
  } else if (
    campaignName?.includes(iohVideoGatingCampaign)
    && LoginStore.iohVideoGating === true
  ) {
    redirectTo(staticRoutes.earnPoints, staticRoutes.earnPoints)
  } else if (LoginStore.pendingFor) {
    LoginStore.pendingForSignup = false
    if (CouponsStore.addToBasketPendingWithLogin) {
      CouponsStore.addToBasket(LoginStore.pendingFor)
      if (!userAlreadyAddedToBasket() && LoginStore.rewardStatus) {
        CouponsStore.postLoginOneTimeAddPrompt = true
        setAlreadyAddedToBasket()
      }
    } else if (digitalCouponsStore.addToBasketPendingWithLogin) {
      await digitalCouponsStore?.fetchdigitalCoupons()
      await digitalCouponsStore?.addToBasket(LoginStore.pendingFor)
      LoginStore.addToBasketPendingWithRegistration = false
      const newCoupon = await digitalCouponsStore?.couponsToDisplay?.filter(
        (coupon) => coupon?.id === LoginStore?.pendingFor,
      )[0]
      if (newCoupon?.id === LoginStore?.pendingFor && newCoupon !== undefined) {
        if (
          !userAlreadyAddedToBasket()
          && LoginStore.rewardStatus
          && digitalCouponsStore.couponsToDisplay.length !== 0
        ) {
          digitalCouponsStore.postLoginOneTimeAddPrompt = true
          document.getElementById('howDoesitworksD')?.scrollIntoView()
          setAlreadyAddedToBasket()
          digitalCouponsStore.clippedCouponerrorScreen = false
        }
      }
    } else if (digitalCouponsStore.sendDownloadLinkPendingwithLogin) {
      digitalCouponsStore.sendDownloadLinkPendingwithLogin()
    } else if (digitalCouponsStore.linkAccountPendingwithLogin) {
      digitalCouponsStore.linkAccountPendingwithLogin()
    } else if (LoginStore.pendingFor === 'completeProfileSurvey') {
      handleLoadCompleteProfileSurvey()
    } else {
      Router.push(LoginStore.pendingFor)
    }
  } else if (campaignName === 'coupons') {
    redirectTo(staticRoutes.coupons, staticRoutes.couponsWithEndSlash)
  } else if (getParameterByName('utm_campaign_coupon') === 'dawn') {
    localStorage.setItem('disableCompleteProfileOverlay', 'true')
    redirectTo(true, staticRoutes.coupons)
  } else if (campaignName === 'sweepstakes') {
    redirectTo(staticRoutes.earnPoints, staticRoutes.earnPoints)
  } else if (campaignName === 'twogether-music-festival') {
    if ((await promoEligibilityCheck('Twogether_Passes')) === true) {
      await issueInteractionApi(
        enrollmentLocationExternalReference.twogetherMusicFestival,
      )
      LoginStore.loginFlowPopup = 'SuccessfulPromoContent'
      redirectTo(staticRoutes.earnPoints, staticRoutes.earnPoints)
      LoginStore.showTwogetherThanksPopUp = true
      LoginStore.loginFlowPopup = 'TmfModalMessageTrue'
    } else {
      sessionStorage.removeItem('campaignSource')
      LoginStore.loginFlowPopup = 'IneligiblePromoContent'
    }
  } else if (campaignName === 'swiffer-coupons') {
    redirectTo(staticRoutes.coupons, staticRoutes.swifferCoupons)
  } else if (campaignName === 'gillette-coupons') {
    redirectTo(staticRoutes.coupons, staticRoutes.gilleteCoupons)
  } else if (campaignName === 'tampax-coupon') {
    redirectTo(staticRoutes.coupons, staticRoutes.tampaxCoupon)
  } else if (campaignName === olayCouponsCampaign) {
    redirectTo(true, staticRoutes.accountSetupOlayCoupons)
  } else if (campaignName === 'olaybody-coupon') {
    redirectTo(true, staticRoutes.olaySuperSerumBodyCoupons)
  } else if (campaignName === myCoupons) {
    redirectTo(true, staticRoutes.personalizedCoupons)
    LoginStore.showCompleteProfile20Survey = false
    LoginStore.showCompleteProfile20OrBuildYourProfileSurvey = false
    sessionStorage.setItem('digital-coupons', 'true')
  } else if (redirectToEarnPointsAfterLogin().indexOf(campaignName) >= 0) {
    redirectTo(staticRoutes.earnPoints, staticRoutes.earnPoints)
  } else if (campaignName === 'sustainability') {
    if (
      await handleCheckRewardTransactionType([
        'Sweep_YO_PG_Complete',
        'Survey_TellMe',
        'PRF_BONUS01',
      ])
    ) {
      LoginStore.OpenCampaignSpecificSurvey = process.env.DEQ_SURVEY_TELL_ME_ABOUT_YOURSELF
    }
    redirectTo(staticRoutes.sustainability, staticRoutes.sustainability)
  } else if (
    typeof sessionStorage !== 'undefined'
    && sessionStorage.getItem('brandName')
  ) {
    redirectTo(true, staticRoutes.coupons)
  } else if (!LoginStore.checkLoginStatus) {
    Router.push('/')
  } else if (
    pageSource === 'LoginPage'
    && LoginStore.simplifiedSignupFlow === true
  ) {
    Router.push(LoginStore.redirectionUrlAfterSimplifiedSignup)
  } else if (pageSource === 'LoginPage') {
    Router.push('/')
  }
  LoginStore.rewardStatus = true
  LoginStore.userLoggedIn = true
  LoginStore.showSignInPopup = false
  LoginStore.simplifiedSignupFlow = false
  LoginStore.simplifiedSignupFlowWithCompleteProfileSurvey = false
  localStorage.removeItem('alreadyRegisteredEmail')
}

/**
 * This function checks the campaignSamplesIdCollection list for the specific campaignname
 * and returns true if it is a SpecialIncentive
 * @param {*} campaignSamplesIdCollection campiagn incentive collection from sitesettings
 * @param {*} sampleName utm_campaign name or the sample url slug
 * @returns boolean
 */
const handleCheckSpecialIncentive = (campaignSamplesIdCollection, sampleName) => {
  let isSpecialIncentive
  campaignSamplesIdCollection?.items?.map((item) => {
    if (item?.name === sampleName) {
      isSpecialIncentive = item?.isSpecialIncentive
    }
    return isSpecialIncentive
  })
  return isSpecialIncentive
}

/**
 * This function used to handle the redirection after user login
 * and performs pre redirection steps like enroll user, charity selection, issueReward
 * @param {*} siteSettings pass the sitesettings prop from contentful
 * @param {*} pageSource from which page login flow is started.
 * @param {*} campaignName utm_campaign name for login
 */
export const handleLoginProcess = async (siteSettings, pageSource, campaignName) => {
  const validCampaign = [
    'downy-unstopables-sample',
    'cascade-platinum-plus-sample',
    'redeem-sample', // need to be removed on march 1
    'redeem-offer-sample', // need to be removed on march 1
    'swiffer-powermop-sample',
    'cascade-digital-coupons',
    'always-coupon',
    'always-discreet-coupon',
    'cincinnati-music-festiva',
    'hbcyou',
    'tide-digital-coupon',
    'dawn-ducks',
    'gillette-labs-coupon',
    'old-spice-coupon',
    'always-discreet-coupon-special-offer',
    'olay-coupons',
    'hs-coupon',
    'oldspice-coupon-family-dollar',
  ]

  try {
    const data = LoginStore.profileData
    LoginStore.profileData = data.profile
    LoginStore.traitCampaignName = campaignName
    handleSession()
    if (data.profile.emailVerified || !process.env.EMAIL_VERIFICATION) {
      LoginStore.charityID = data.profile?.traitCharitableCause?.traitValue === null
        || data.profile?.traitCharitableCause?.traitValue === '018GPK'
        ? defaultCharity
        : data.profile?.traitCharitableCause?.traitValue
      if (validCampaign.indexOf(campaignName) >= 0) {
        if (!data.enrolledForRewards) {
          if (campaignName.includes('-sample')) {
            let accountSetupUrl = `/account-setup/${campaignName}/`
            const { reRoutePath } = LoginStore
            if (reRoutePath) {
              const utms = reRoutePath && reRoutePath.split('?')[1]
              accountSetupUrl += utms ? `?${utms}` : ''
            }
            Router.push(accountSetupUrl)
          } else {
            sessionStorage.removeItem('campaignSource')
            LoginStore.loginFlowPopup = 'IneligiblePromoContent'
          }
        } else if (campaignName.includes('-sample')) {
          const incentiveData = await handleGetIncentiveData(
            siteSettings,
            campaignName,
          )
          if (
            incentiveData?.categories?.includes(REWARD)
            && (incentiveData?.customTags?.includes(REWARD_CATALOG)
              || (incentiveData?.customTags?.includes(OFFER_CATALOG)
                && handleCheckSpecialIncentive(
                  siteSettings.campaignSamplesIdCollection,
                  campaignName,
                )))
          ) {
            let accountSetupUrl = `/account-setup/${campaignName}/`
            const { reRoutePath } = LoginStore
            if (reRoutePath) {
              const utms = reRoutePath && reRoutePath.split('?')[1]
              accountSetupUrl += utms ? `?${utms}` : ''
            }
            Router.push(accountSetupUrl)
          } else if (
            incentiveData?.categories?.includes(REWARD)
            && incentiveData?.customTags?.includes(OFFER_CATALOG)
          ) {
            LoginStore.userLoggedIn = true
            LoginStore.rewardStatus = true
            localStorage.setItem('loggedIn', true)
            await fetchPointsBalanceApi()
            LoginStore.triggerTrakers = 'login-success'
            setLoginCount()
            LoginStore.loginFlowPopup = 'IneligiblePromoContent'
          }
        } else if (revtraxDigitalCouponsCampaign.includes(campaignName)) {
          LoginStore.cascadeDigitalCampaignName = true
          const accountSetupUrl = `/account-setup/${campaignName}/`
          Router.push(accountSetupUrl)
          LoginStore.userLoggedIn = true
          LoginStore.rewardStatus = true
          localStorage.setItem('loggedIn', true)
          LoginStore.triggerTrakers = 'login-success'
        } else {
          sessionStorage.removeItem('campaignSource')
          LoginStore.loginFlowPopup = 'IneligiblePromoContent'
        }
      } else if (data.enrolledForRewards) {
        LoginStore.userLoggedIn = true
        LoginStore.rewardStatus = true
        localStorage.setItem('loggedIn', true)
        await fetchPointsBalanceApi()
        LoginStore.triggerTrakers = 'login-success'
        setLoginCount()
        LoginStore.getLoginCount = getLoginCount()
        handleConfirmationModalClose(pageSource, campaignName)
        if (window.location.pathname.includes('/email-verification')) {
          Router.push('/')
        }
      } else if (checkForPGEUser(LoginStore.profileData)) {
        // for PGE user
        LoginStore.traitCampaignName = campaignName
        const rewardAssignmentStatus = handleEnrollAndRewardAssignment(
          siteSettings,
          Router,
          data.pggeUser,
        )
        if (!rewardAssignmentStatus) {
          LoginStore.showErrorModal = true
          Router.push(staticRoutes.homepage)
        } else LoginStore.triggerTrakers = 'campaign'
      } else {
        handleRedirectToCharity(Router)
        if (
          digitalCouponsStore.addToBasketPendingWithLogin === true
          || LoginStore.sendOTPPendingWithRegistration === true
        ) {
          LoginStore.PGEUserLoginTriggeredFromDigitalCoupons = true
        }
      }
    } else {
      // write email verification logic here
      // or logout
      LoginStore.showErrorModal = true
      Router.push(staticRoutes.homepage)
    }
  } catch (error) {
    LoginStore.showErrorModal = true
    Router.push(staticRoutes.homepage)
  }
}

/**
 * This function used to show or hide the contexual login/signup popup modal
 * true means show the popup modal
 * false means hide the popup modal
 * @param {boolean} click pass true or false value to show the contexual popup
 */
export const handleClick = (click) => {
  if (!storageAvailable('localStorage')) {
    LoginStore.showCookiesDisabledodal = true
    return
  }
  if (!click && window.location.href.includes('?receiptscan')) {
    Router.push('/earn-points')
    localStorage.removeItem('pendingfor')
  }
  if (click) {
    if (
      window.location.href.includes('ioh-video-gating')
      && LoginStore.iohVideoGating
    ) {
      Router.push(`${staticRoutes.login}${window.location.search}`)
      return
    }
    if (window.location.href.includes('signup')) Router.push(staticRoutes.login)
    else {
      localStorage.removeItem('alreadyRegisteredEmail')
      LoginStore.context = loginContextText.generic
      LoginStore.showSignInPopup = click
    }
  } else {
    LoginStore.showSignInPopup = click
    LoginStore.openFeedbackSurvey = click
    LoginStore.openSurvey = click
    sessionStorage.removeItem('surveyId')
    localStorage.removeItem('open-surveyId')
  }
  if (!click) LoginStore.pendingFor = false
}

/**
 * This function used to handle the redirection after user registration
 * and performs pre redirection steps like enroll user, charity selection, issueReward
 * @param {*} siteSettings pass the sitesettings prop from contentful
 * @param {*} campaignName for utm_campaign campaign name.
 * @param {*} allUTMs pass all the query param string
 */
export const handleRegistrationProcess = async (siteSettings, campaignName, allUTMs) => {
  const data = LoginStore.profileData
  const charityName = handleGetCharityIdDuringRegistration(
    siteSettings,
    campaignName,
  )
  const response = await enrollPGGEUserWithWelcomeEmail(
    charityName,
  )
  const enrollResponseJson = await response.json()
  console.info(enrollResponseJson)

  if (response.status === 200) {
    LoginStore.redirectionUrlAfterSimplifiedSignup = localStorage.getItem(
      'redirectionUrlAfterSimplifiedSignup',
    )
    localStorage.removeItem('redirectionUrlAfterSimplifiedSignup')
    LoginStore.simplifiedSignupFlowWithCompleteProfileSurvey = localStorage.getItem(
      'simplifiedSignupFlowWithCompleteProfileSurvey',
    )
    localStorage.removeItem('simplifiedSignupFlowWithCompleteProfileSurvey')
    const showSurveyAfterCampaignSignup = localStorage.getItem(
      'showSurveyAfterCampaignSignup',
    )
    localStorage.removeItem('showSurveyAfterCampaignSignup')
    redirectionUrlAfterSkipping2Screen = localStorage.getItem(
      'redirectionUrlAfterSkipping2Screen',
    )
    localStorage.removeItem('redirectionUrlAfterSkipping2Screen')
    LoginStore.blueBarSignup = localStorage.getItem('blueBarSignup')
    localStorage.removeItem('blueBarSignup')
    LoginStore.traitCampaignName = campaignName
    LoginStore.profileData = data.profile
    LoginStore.charityID = LoginStore.profileData.traitCharitableCause.traitValue = charityName

    if (
      getParameterByName('flow') === 'simplified-signup'
      && LoginStore.simplifiedSignupFlowWithCompleteProfileSurvey
    ) {
      redirectionUrlAfterSkipping2Screen = LoginStore.redirectionUrlAfterSimplifiedSignup
      LoginStore.showCompleteProfile20Survey = true
    } else if (getParameterByName('flow') === 'simplified-signup') {
      redirectionUrlAfterSkipping2Screen = LoginStore.redirectionUrlAfterSimplifiedSignup
      LoginStore.showCompleteProfile20Survey = true
    } else if (LoginStore.videoGating === true) {
      LoginStore.charityID = LoginStore.profileData.traitCharitableCause.traitValue = defaultCharity
      redirectionUrlAfterSkipping2Screen = staticRoutes.earnPoints
      localStorage.setItem('disableCompleteProfileOverlay', 'true')
    } else if (LoginStore.iohVideoGating === true) {
      LoginStore.charityID = LoginStore.profileData.traitCharitableCause.traitValue = defaultCharity
      redirectionUrlAfterSkipping2Screen = staticRoutes.earnPoints
    } else if (revtraxDigitalCouponsCampaign.includes(campaignName)) {
      LoginStore.cascadeDigitalCampaignName = true
      redirectionUrlAfterSkipping2Screen = `/account-setup/${campaignName}/`
    } else if (campaignName === 'hbcyou') {
      redirectionUrlAfterSkipping2Screen = staticRoutes.coupons
    } else if (showSurveyAfterCampaignSignup) {
      localStorage.setItem('disableCompleteProfileOverlay', 'true')
      LoginStore.OpenCampaignSpecificSurvey = getSurveyId(
        showSurveyAfterCampaignSignup.split('-')[1],
      )
    } else if (
      campaignName === 'over' || LoginStore.reRoutePath === `${process.env.SITE_DOMAIN.trim()}/` || LoginStore.reRoutePath === `${process.env.SITE_DOMAIN.trim()}/signup/` || LoginStore.blueBarSignup
    ) {
      redirectionUrlAfterSkipping2Screen = staticRoutes.couponsBrandSaver
      LoginStore.showCompleteProfile20Survey = true
    } else if (
      !LoginStore.addToBasketPendingWithRegistration
      && !LoginStore.sendOTPPendingWithRegistration
      && campaignName?.includes('account-setup')
    ) {
      redirectionUrlAfterSkipping2Screen = staticRoutes.earnPoints
      LoginStore.openTellMeAboutSurvey = true
    }

    LoginStore.rewardStatus = true
    LoginStore.userLoggedIn = true
    localStorage.setItem('loggedIn', true)
    if (
      campaignName === 'global-citizen'
      || campaignName === 'its-our-home'
      || campaignName === 'its-our-home2'
    ) {
      // await issueRewardApi('charity_2_011ADS', 1, 'donation')
    } else if (campaignName === 'cincinnati-music-festival') {
      await issueRewardApi(
        enrollmentLocationExternalReference.cincinnatiMusicFestival,
        1,
        'Sweeps',
      )
      LoginStore.showCincinnatiThanksPopUp = true
    } else if (campaignName === myCoupons) {
      sessionStorage.setItem('digital-coupons', 'true')
      LoginStore.showCompleteProfile20Survey = false
    }
    LoginStore.triggerTrakers = 'campaign'
    handleRedirectToCharity(Router, redirectionUrlAfterSkipping2Screen, allUTMs)
  } else {
    LoginStore.showErrorModal = true
    Router.push(staticRoutes.homepage)
  }
  handleSession()
}
